import React from "react";
import { Typography } from "@mui/material";
import { ReactComponent as CirclePlusIcon } from "src/assets/images/icons/feather-plus-circle.svg";
import AccordionComp from "../UI/Accordian";

const GeneralQuery = () => {
  const acc_summery = [
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          Critical <strong> Care Medicine MCQ's </strong>: What is Go the Extra
          Mile?{" "}
        </Typography>
      ),
      content: (
        <>
          <Typography>
            Go the Extra Mile is an online platform for
            <strong> MCQ in Critical Care Medicine.</strong> GTEM is one of the
            best web based resources to<strong> prepare for EIDC exam.</strong>
          </Typography>
          <br />
          <Typography>The MCQ tests have the following features:</Typography>
          <ul>
            <ol>
              <li>Online tests</li>
              <li>Timer</li>
              <li>Instant marking upon completion</li>
              <li>Detailed feedback</li>
            </ol>
          </ul>
          <Typography>
            The MCQs are organized in the form of tests. There are two kinds of
            <strong> Critical Care medicine tests: </strong>
          </Typography>

          <ul>
            <li>
              Exam Style tests (e.g., EDIC Omnibus)
              <ul>
                <li>30 MCQs per test</li>
                <li>The order of choices is randomized where appropriate</li>
                <li>Immediate score at the end of the test</li>
                <li>Complete feedback at the end of the test</li>
                <li>The same questions as the Learn-X Style tests</li>
              </ul>
            </li>
            <li>
              Learn-X Style Tests (For learning & Revision)
              <ul>
                <li>10 MCQs per test</li>
                <li>999 minutes available to complete the test</li>
                <li>Feedback immediately after every QUESTION</li>
                <li>Feedback again at completion</li>
                <li>The ‘Lx’ prefix indicates Learn-X style tests</li>
                <li>Available in Learn-X or EDIC Omnibus + Learn-X.</li>
                <li>Same questions as Exam Style tests.</li>
              </ul>
            </li>
          </ul>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          How many <strong>Critical Care Medicine MCQs</strong> are there on GO
          THE EXTRA MILE?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            GO THE EXTRA MILE has over{" "}
            <span className="danger">3100 Critical Care Medicine MCQs</span>. To
            our knowledge, Go the Extra Mile is the world’s largest MCQ database
            for Critical Care Medicine.
          </Typography>
          <br />
          <Typography>
            About 200 new <strong>MCQs for critical care</strong> medicine are
            added each year. They are automatically added to the subscription.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: <Typography>Does Go the Extra Mile have an App?</Typography>,
      content: (
        <>
          <Typography>Yes.</Typography>
          <Typography>
            <strong>‘Critical Care MCQ’</strong> by Go the Extra Mile is
            available through the Apple App Store and Google Play Store.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          How is GO THE EXTRA MILE different from{" "}
          <strong>Critical care MCQ books?</strong>
        </Typography>
      ),
      content: (
        <>
          <Typography>
            GO THE EXTRA MILE MCQ has the following features:
          </Typography>
          <ul>
            <li>Random order of MCQ when doing a test</li>
            <li>
              Randomization of the order of choices when doing a test.
              Randomization minimizes bias on the part of the writer.
            </li>
            <li>
              Exam-like conditions – A Timer is running when you do a test
            </li>
            <li>
              MCQ based on videos (e.g., for Ventilator waveforms,
              Echocardiogram)
            </li>
            <li>
              MCQ based on images (e.g., ECMO) and screenshots (e.g., Ventilator
              waveforms)
            </li>
            <li>Immediate automatic score</li>
            <li>Detailed feedback</li>
            <li>Review your score in the dashboard</li>
          </ul>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          How can I ascertain that Go the Extra Mile is right for me?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            Please download the app. There are over 500 sample
            <strong> MCQs for critical care</strong>. Please try some or all the
            MCQs to see if it suits your needs.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          What type of MCQ are available on Go the Extra Mile?
        </Typography>
      ),
      content: (
        <>
          <Typography>The following types of MCQ are available:</Typography>
          <ul>
            <ol>
              <li>
                Single best answer (Out of four options): Similar to Type A MCQ{" "}
              </li>
              <li>
                Multiple Answers (Out of five or more options): Similar to Type
                K
              </li>
              <li>Match the columns</li>
              <li>True-False </li>
            </ol>
          </ul>
          <Typography>
            Please note that in some exams, Type A MCQs have five choices, and
            Type K MCQs have four.{" "}
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          How long is the subscription duration, and how many attempts can I
          take?
        </Typography>
      ),
      content: (
        <>
          <Typography>The duration of the subscription is one year.</Typography>

          <Typography>
            You can attempt each test for up to 3 times during that time.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          How can I keep track of the tests I have completed and my performance?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            You can see the results of your previous tests in the dashboard.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          I am an ICU nurse. How can I use Go the Extra Mile to orient myself to
          ICU?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            Commencement of first term in the ICU may make anyone nervous.
            Managing the critically ill is a complex undertaking, and the sheer
            volume of data each patient generates may get overwhelming. But like
            any seemingly complicated task, what you need to know as you begin
            your term (or career) in intensive care may be divided into building
            blocks. These building blocks form the foundation of intensive care
            medicine.
          </Typography>
          <br />
          <Typography>
            Under ‘Nursing’, several <strong>ICU nursing tests</strong> will
            help you manage your continuing medical education. Each test has 30
            questions. The pass marks are set at 70%. Each test may be taken 3
            times. Upon passing the test, a certificate of completion will be
            emailed. Each test should take approximately 45 minutes (Factoring
            possible multiple attempts to get passing marks), which shall be
            stated on the certificate.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          How do the MCQs on Go the Extra Mile differ from the EDIC Exam?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            GTEM is not endorsed by any governing body conducting Intensive Care
            Medicine exams. Those who have subscribed to GTEM and then completed
            EDIC Part I have provided feedback that the MCQ on GO THE EXTRA MILE
            is relatively easier than the EDIC Part I exam.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          Why does GO THE EXTRA MILE not provide all MCQ for free?{" "}
        </Typography>
      ),
      content: (
        <>
          <Typography>
            The subscription fee helps cover running costs. Go the Extra Mile
            does not have any paid employees. Since its inception in 2017, 100%
            of the profits have been donated to charity. Go the Extra Mile is a
            Bronze Level{" "}
            <span className="danger">
              ‘Champion for Children’ for UNICEF and ‘Proudly Supports’ Médecins
              Sans Frontières (MSF, Doctors Without Borders).{" "}
            </span>
            Additionally, GTEM supports several local and international
            charities every year. In 2024-25, Go the Extra Mile will contribute
            to planting 200 trees over one year through{" "}
            <span className="danger">Greenfleet Australia.</span>
          </Typography>
          <br />
          <Typography>
            GTEM operates without advertisements and maintains low running costs
            by having no dedicated office space and no employees. It is a
            one-person undertaking—the director is responsible for writing
            questions, reviewing invited tests, managing study groups, handling
            social media, and everything else with the territory. The director
            does not receive any salary or remuneration for his time and work
            towards GTEM, as the work itself is its reward.
          </Typography>
        </>
      ),
    },
  ];

  return (
    <>
      <AccordionComp acc_data={acc_summery} />
    </>
  );
};
export default GeneralQuery;
