import React from "react";
import Page from "../../component/Page";
import PrivacyPolicy from "../component/PrivacyPolicy/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Page
        title="GTEM Privacy Policy | How We Protect Your Data"
        description="Learn how GTEM safeguards your personal data with our comprehensive privacy policy, ensuring your information is secure."
        keywords="GTEM Privacy Policy"
      >
        <PrivacyPolicy />
      </Page>
    </>
  );
};
export default PrivacyPolicyPage;
