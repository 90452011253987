import React from "react";
import Page from "../../component/Page";
import TermsConditions from "../component/Terms&Conditions/TermsConditions";

const TermsConditionsPage = () => {
  return (
    <>
      <Page
        title="GTEM Terms and Conditions | Important Information for Users"
        description="Learn about the terms of use, policies, and guidelines when using GTEM’s services. Important details for users to know."
        keywords="GTEM Terms and Conditions"
      >
        <TermsConditions />
      </Page>
    </>
  );
};
export default TermsConditionsPage;
