import React from "react";
import Page from "../../component/Page";
import FreeTest from "../component/FreeTests/FreeTests";

const FreeTestpage = () => {
  return (
    <>
      <Page
        title="Prepare for Intensive Care MCQ exams | Try on our free App ‘Critical Care MCQ’ on App Store and Google Play Store"
        description="Prepare for Intensive Care MCQ exams like EDIC I, FFICM, DM & American Boards with our free ‘Critical Care MCQ’ app. Available on App Store & Google Play!"
        keywords="Intensive care MCQ"
      >
        <FreeTest />
      </Page>
    </>
  );
};
export default FreeTestpage;
