import React from "react";
import Page from "../../component/Page";
import Faq from "../component/FAQ/Faq";

const FaqPage = () => {
  return (
    <>
      <Page
        title="GTEM FAQ | Expert Answers for Intensive Care Professionals"
        description="Find answers to your questions about subscription options at GTEM. Explore our FAQ for helpful guidance and tips."
        keywords="GTEM FAQ"
      >
        <Faq />
      </Page>
    </>
  );
};
export default FaqPage;
