import React from "react";
import Page from "../../component/Page";
import Home from "../component/Home";
import { useLocation } from "react-router-dom";

const HomePage = () => {
  const location = useLocation();

  const redirectId = location.hash.replace("#", "");

  return (
    <>
      <Page
        title={
          redirectId === "subscription"
            ? "GTEM Intensive Care MCQ | Choose Your Subscription"
            : redirectId === "aboutus"
            ? "About GTEM | Redefining Intensive Care MCQ Test Resources"
            : redirectId === "testimonials"
            ? "GTEM Success Stories | Hear from Intensive Care Trainees who have used GTEM"
            : redirectId === "contact"
            ? "GTEM Intensive Care MCQ Test | Contact Us"
            : "GTEM | Australian Intensive Care ICU MCQ Test Online"
        }
        description={
          redirectId === "subscription"
            ? "MCQ for European Diploma in Intensive Care Part I, NEET SS, INI SS Critical Care (India), DM, FFICM (UK) & American Boards of Intensive Care. GTEM is the world's largest collection of online MCQ in Critical Care Medicine. Do MCQ on Critical Care MCQ App."
            : redirectId === "aboutus"
            ? "ECMO, CRRT, Ventilator Waveforms, Mechanical Ventilation, Ventilator Waveform Videos, Post Cardiac Surgical Intensive Care, Respiratory Physiology, Critical Care Pharmacology & more."
            : redirectId === "testimonials"
            ? "Explore GTEM success stories from intensive care professionals who have transformed their careers with our expert-designed resources."
            : redirectId === "contact"
            ? "Get in touch with GTEM for any inquiries about our Intensive Care MCQ Test. Click on the WhatsApp icon to get directly in touch."
            : "MCQ for European Diploma in Intensive Care Part (EDIC) I, NEET SS, INI SS Critical Care (India), DM, FFICM (UK) & American Boards of Intensive Care. GTEM is the world's largest collection of online MCQ in Critical Care Medicine. Online tests, Android and iOS App, immediate results and complete feedback."
        }
        revisedDescription={
          redirectId === "subscription" ||
          redirectId === "aboutus" ||
          redirectId === "testimonials" ||
          redirectId === "contact"
            ? ""
            : "GTEM offers the world’s largest collection of online MCQs in Critical Care Medicine for EDIC I, NEET SS, INI SS, DM, FFICM & American Boards. Get instant results, complete feedback, and access via web & mobile apps."
        }
        keywords={
          redirectId === "subscription" || redirectId === "aboutus"
            ? "Intensive Care MCQ"
            : redirectId === "testimonials"
            ? "Intensive care experts"
            : redirectId === "contact"
            ? "Contact GTEM"
            : "intensive care icu"
        }
      >
        <Home />
      </Page>
    </>
  );
};

export default HomePage;
