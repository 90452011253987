import React from "react";
import Page from "../../component/Page";
import TermsOfUse from "../component/TermsOfUse/TermsOfUse";

const TermsOfUsePage = () => {
  return (
    <>
      <Page
        title="GTEM Terms of Use | Understand Our Service Guidelines"
        description="Review GTEM's Terms of Use to understand the guidelines and conditions for accessing and using our platform."
        keywords="GTEM Terms of Use"
      >
        <TermsOfUse />
      </Page>
    </>
  );
};
export default TermsOfUsePage;
