import React, { forwardRef, useEffect } from "react";
import { Helmet } from "react-helmet";

const Page = forwardRef(
  (
    {
      children,
      title = "",
      description = "",
      keywords = "",
      revisedDescription = "",
      ...rest
    },
    ref
  ) => {
    useEffect(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, [window.location.pathname]);

    return (
      <div ref={ref} {...rest}>
        <Helmet
          onChangeClientState={(newState) => {
            // Add description
            const metaDescription = document.querySelector(
              'meta[name="description"]'
            );
            if (metaDescription) {
              metaDescription.setAttribute("content", description || "");
            }
            // revised description
            const metaRevisedDescription = document.querySelector(
              'meta[name="revised"]'
            );
            if (metaRevisedDescription) {
              metaRevisedDescription.setAttribute(
                "content",
                revisedDescription || ""
              );
            }
            // Keywords
            const keywordsTag = document.querySelector('meta[name="keywords"]');
            if (keywordsTag) {
              keywordsTag.setAttribute("content", keywords || "");
            }
          }}
        >
          <title>{title}</title>
          {/* <meta name="description" content={description} /> */}
        </Helmet>
        {children}
      </div>
    );
  }
);

export default Page;
