import React from "react";
import { Link, Typography } from "@mui/material";
import { ReactComponent as CirclePlusIcon } from "src/assets/images/icons/feather-plus-circle.svg";
import AccordionComp from "../UI/Accordian";

const SubscriptionQuery = () => {
  const acc_summery = [
    {
      icon: <CirclePlusIcon />,
      summery: <Typography>What are the subscription options?</Typography>,
      content: (
        <>
          <Typography>
            You can do online tests for one year when you subscribe to GTEM. The
            popular subscription options are as follows. All the following are
            <strong> one-year subscriptions</strong>. Every test may be taken up
            to <strong>three times</strong> during the subscription duration.
          </Typography>
          <ul>
            <li>
              <strong>EDIC Omnibus </strong>– Contains nearly all MCQ on
              GTEM—exam-style tests (See FAQ 1 above).
            </li>
            <li>
              <strong>Learn-X </strong>– Contains nearly all MCQ on GTEM. It has
              Learn-x Style questions (See FAQ 1 above).
            </li>
            <li>
              <strong>EDIC Omnibus + Learn-X </strong>– Contains all MCQ
              available on GO THE EXTRA MILE in both formats – Exam style and
              Learn-X style (See FAQ 1). It is the most comprehensive
              subscription option on Go the Extra Mile.
            </li>
            <li>
              <strong>Primary Omnibus </strong>– For primary exam in Intensive
              Care – It is a work in progress and does not cover the full scope
              of the Critical Care Primary curriculum.
            </li>
            <li>
              <strong>Anaesthetic Primary </strong>– For primary exam in
              Anaesthetics. It is a work in progress and does not cover the full
              scope of the Anaesthetic Primary Curriculum. 
            </li>
          </ul>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          What is the difference between Omnibus and Chapter?
        </Typography>
      ),
      content: (
        <>
          <ul className="ul-lower-alpha">
            <li>
              CHAPTER – A collection of tests for a given topic. For example,
              ECMO – contains only the tests related to ECMO.
            </li>
            <li>
              Omnibus – A collection of tests for specific exam(s). For example,
              <ul className="ul-decimal">
                <li>
                  EDIC Omnibus has a majority of the questions available at Go
                  the Extra Mile
                </li>
                <li>Crit Care Curriculum Omnibus </li>
                <li>Anaesthetic Primary</li>
              </ul>
            </li>
          </ul>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          Do I own the questions if I subscribe to an omnibus or a test on GO
          THE EXTRA MILE?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            No. The questions and the explanation provided remain the property
            of GTEM. Subscription to a package allows you to attempt the tests
            online. You cannot access the tests at the end of your subscription
            or once you have exhausted the permitted number of attempts. A
            chapter or omnibus subscription provides you access for 12 months.
            The questions remain the property of GTEM.
          </Typography>
          <br />
          <Typography>
            The explanations provided for the detailed review of the tests
            should not be used as a substitute for standard reading material
            like textbooks.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          I did not finish my tests before the subscription expired. Can I get
          an extension on my subscription?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            No. GTEM is unable to extend the subscription. Prospective trainees
            must choose the time to start their subscriptions thoughtfully. For
            example, if the exam is 13 months away, it may be appropriate to
            wait for a subscription until the exam is less than 12 months later.
            This avoids premature loss of access to the tests.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          How can I make a payment to subscribe to Go the Extra Mile?{" "}
        </Typography>
      ),
      content: (
        <>
          <Typography>
            Access to the tests is available only after the payment has been
            accepted. Payment to GTEM may be made in one of the following ways –
          </Typography>
          <ul>
            <ol className="ul-lower-alpha">
              <li>
                Stripe – Stripe is an online payment gateway for payment using a
                credit card or a debit card. You don’t necessarily need to have
                a Stripe account. Stripe securely handles your card details. Go
                the Extra Mile cannot access your card details when using
                Stripe.
              </li>
              <li>
                Critical Care MCQ App on Android or iOS – As an in-app purchase.
              </li>
            </ol>
          </ul>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          Does GO THE EXTRA MILE provide institutional subscriptions?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            Yes. The institutional subscriptions are discounted. The
            participants may get up to 50% discount.
          </Typography>
          <br />
          <Typography>
            Please email{" "}
            <Link
              href="mailto:support@gotheextramile.com"
              className="email_link"
            >
              support@gotheextramile.com
            </Link>{" "}
            for details
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          I am a nurse educator in my ICU. How can I use GO THE EXTRA MILE to
          orient new nurses in the ICU?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            You may use the services at GTEM for both the orientation of the
            recruits and for CME (Continuing Medical Education). Under
            ‘Nursing’, several tests will help you manage your continuing
            medical education. Each test has 30 questions. The pass marks are
            set at 70%. Each test may be taken 3 times. Upon passing the test, a
            certificate of completion will be emailed. Each test should take
            approximately 45 minutes (Factoring possible multiple attempts to
            get passing marks), which shall be stated on the certificate. The
            certificates may be used as evidence of completion of tests.
          </Typography>
          <br />
          <Typography>
            For institutional orders, discounted price may be available. Please
            email{" "}
            <Link
              href="mailto:support@gotheextramile.com"
              className="email_link"
            >
              support@gotheextramile.com
            </Link>{" "}
            for discount offers.
          </Typography>
        </>
      ),
    },
  ];

  return (
    <>
      <AccordionComp acc_data={acc_summery} />
    </>
  );
};
export default SubscriptionQuery;
